import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "./LoadingSpinner";
import { awayPlayer, awayPlayerClub, homePlayer, homePlayerClub } from "./testData";
import { config } from "./constants";

const playWhistle = () => {
	new Audio("/whistle.mp3").play();
}

const URL = config.url;


export const GameView = () => {
	let { id } = useParams();
	const [running, setRunning] = useState(false);
	const [timeLeft, setTimeLeft] = useState<string|null>(null);
	const [homeGoals, setHomeGoals] = useState(0);
	const [awayGoals, setAwayGoals] = useState(0);

	const handleAddHomeGoal = () => {
		setHomeGoals(homeGoals + 1);
		handlePauseTimer();
	};

	const handleAddAwayGoal = () => {
		setAwayGoals(awayGoals + 1);
		handlePauseTimer();
	};

	const handleStartTimer = async () => {
		await fetch(`${URL}/timer/start/${id}`, {
			method: "post",
			headers: { "Content-Type": "application/json" },
			// body: {
			//  "first_name": this.firstName.value
			// }
		});
		// const data = await res.text();
		setRunning(true);
		playWhistle();
	};

	const handlePauseTimer = async () => {
		await fetch(`${URL}/timer/stop/${id}`, {
			method: "post",
			headers: { "Content-Type": "application/json" },
			// body: {
			//  "first_name": this.firstName.value
			// }
		});
		// const data = await res.text();
		setRunning(false);
		playWhistle();
	};

	const handleRestartTimer = async () => {
		await fetch(`${URL}/timer/reset/${id}`, {
			method: "post",
			headers: { "Content-Type": "application/json" },
			// body: {
			//  "first_name": this.firstName.value
			// }
		});
		// const data = await res.text();
		setRunning(false);
	};

	useEffect(() => {
		const handleGetTime = async () => {
			const res = await fetch(`${URL}/timer/${id}`);
			const data = await res.text();
			if (data === "0:00"){
				clearInterval(timer);
			}
			setTimeLeft(data);
		};

		const timer = setInterval(handleGetTime, 500);
		return () => clearInterval(timer);
	}, [id]);

	const scoreBoard = () => {
		return (
			<div className="flex w-full">
				<div className="flex w-full justify-start">
					<div className="flex flex-col">
						<div className="flex justify-center">
							<img
								className="h-10 w-10 rounded-full"
								src={"/default-profile.svg"}
								alt=""
							/>
						</div>
						<div>
							{homePlayerClub
								? homePlayerClub.clubName
								: homePlayer?.userName}
						</div>
					</div>
				</div>
				<div className="flex justify-center w-full text-2xl">
					<div className="text-4xl bold text-orange-600">
						{homeGoals} : {awayGoals}
					</div>
				</div>
				<div className="flex w-full justify-end">
					<div className="flex flex-col">
						<div className="flex justify-center">
							<img
								className="h-10 w-10 rounded-full"
								src={"/default-profile.svg"}
								alt=""
							/>
						</div>
						<div>
							{awayPlayerClub
								? awayPlayerClub.clubName
								: awayPlayer?.userName}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const timeClock = () => {
		return (
			<div className="overflow-hidden rounded-lg bg-white shadow">
				<div className="px-4 py-5 sm:p-6">
					<div className="w-full flex justify-between">
						<div></div>
						<div onClick={handleRestartTimer}>
							<ArrowPathIcon className="h-6" />
						</div>
					</div>
					<div className="flex justify-center">
						{!timeLeft ? (
							<h1 className="text-9xl"><LoadingSpinner /></h1>
						) : (
							<h1 className="text-9xl">{timeLeft}</h1>
						)}
						
					</div>
					<div className="flex justify-between w-full">
						<button
							type="button"
							className="w-1/3 rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 disabled:opacity-75"
							onClick={handleStartTimer}
							disabled={running}
						>
							Start
						</button>
						<button
							type="button"
							className="w-1/3 rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 disabled:opacity-75"
							onClick={handlePauseTimer}
							disabled={!running}
						>
							Stop
						</button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
				<div className="px-4 py-5 sm:p-6">{scoreBoard()}</div>
				<div className="px-4 py-4 sm:px-6">
					<button
						type="button"
						className="h-12 w-full rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
						onClick={handleAddHomeGoal}
					>
						Home Goal
					</button>{" "}
					<button
						type="button"
						className="h-12 mt-4 w-full rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
						onClick={handleAddAwayGoal}
					>
						Away Goal
					</button>
				</div>
			</div>
			<div className="mt-4">{timeClock()}</div>
		</>
	);
};
